import React from "react"
import LineIcon from "react-lineicons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function ContactForm() {
  const [state, setState] = React.useState({})
  const [success, setSuccess] = React.useState(false)

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleClick = e => {
    e.preventDefault()
    setSuccess(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => setSuccess(true))
      .catch(error => alert(error))
  }
  const isBrowser = typeof window !== "undefined"

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="contact-wrapper form-style-two pt-0">
            <h4 className="contact-title pb-10">
              {isBrowser && <LineIcon name="envelope" />}
              <span>Get a quotation or leave a feedback.</span>
            </h4>
            {success ? (
              <div>
                <div className="row d-flex justify-content-center mt-45 p-5 text-center">
                  <div className="mb-3">
                    <FontAwesomeIcon
                      size="6x"
                      icon={faCheckCircle}
                      color="#00aff0"
                    />
                  </div>
                  <div>
                    <h4 className="contact-title pb-10">
                      Thank You for contacting us. We will reach out to you
                      soon.
                    </h4>
                    <div className="form-input light-rounded-buttons mt-30">
                      <button
                        onClick={handleClick}
                        className="main-btn light-rounded-two"
                      >
                        Send Another Message
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <form
                id="contact-form"
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                  <label>
                    Don’t fill this out:
                    <input name="bot-field" onChange={handleChange} />
                  </label>
                </p>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-input mt-25">
                      <label htmlFor="name">Name</label>
                      <div className="input-items default">
                        <input
                          name="name"
                          type="text"
                          placeholder="Name"
                          onChange={handleChange}
                          required={true}
                        />
                        {isBrowser && <LineIcon name="user" />}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-input mt-25">
                      <label htmlFor="email">Email</label>
                      <div className="input-items default">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          onChange={handleChange}
                          required={true}
                        />
                        {isBrowser && <LineIcon name="envelope" />}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-input mt-25">
                      <label htmlFor="phone">Phone</label>
                      <div className="input-items default">
                        <input
                          type="text"
                          name="phone"
                          placeholder="Phone"
                          onChange={handleChange}
                          required={true}
                        />
                        {isBrowser && <LineIcon name="phone" />}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-input mt-25">
                      <label htmlFor="message">Message</label>
                      <div className="input-items default">
                        <textarea
                          name="message"
                          placeholder="Message"
                          onChange={handleChange}
                          required={true}
                        />
                        {isBrowser && <LineIcon name="pencil-alt" />}
                      </div>
                    </div>
                  </div>
                  <p className="form-message"></p>
                  <div className="col-md-12">
                    <div className="form-input light-rounded-buttons mt-30">
                      <button className="main-btn light-rounded-two">
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
