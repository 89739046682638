import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Card, Col, Container, Row } from "react-bootstrap"
import Contact from "../components/contact"
import SliderBanner from "../components/sliderbanner"
import Title from "../components/title"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFire } from "@fortawesome/free-solid-svg-icons"
import Product from "../components/product"

const highlightedProducts = [
  {
    id: "1",
    image: "DELL5440.png",
    name: "Dell 5440",
    processor: "i5 4th Gen",
    memory: "4GB RAM",
    storage: "320 GB HDD",
  },
  {
    id: "2",
    image: "DELL5440.png",
    name: "Dell 5440 ",
    processor: "i5 4th Gen",
    memory: "8GB RAM",
    storage: "240 GB SSD",
  },
  {
    id: "3",
    image: "DELL6430.png",
    name: "Dell 6430",
    processor: "i5 3rd Gen",
    memory: "4GB RAM",
    storage: "320 GB HDD",
  },
  {
    id: "4",
    image: "DELL6430.png",
    name: "Dell 5430",
    processor: "i5 3rd gen",
    memory: "8GB RAM",
    storage: "240 GB SSD",
  },
]

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Lappy Nation" />
      <SliderBanner />
      <Row as="section" bsPrefix id="laptops" className="features-area pt-5">
        <Container>
          <Row className="justify-content-center">
            {highlightedProducts.map(product => (
              <Product
                key={product.id}
                image={product.image}
                name={product.name}
                processor={product.processor}
                memory={product.memory}
                storage={product.storage}
                col={3}
              />
            ))}
          </Row>
        </Container>
      </Row>
      <Row as="section" bsPrefix id="why-us" className="single-features pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col sm={12} md={6} lg={6}>
              <Card style={{ border: "none" }} className="mb-5">
                <Card.Body>
                  <Card.Title className="text-uppercase">
                    <h4>WHY LAPPY NATION?</h4>
                  </Card.Title>
                  <Card.Text className="lead">
                    Lappy Nation is one of the developing Information Technology
                    Company, giving a wide scope of administrations from
                    Students to Business, from Scholars to professionals both
                    for huge and little ventures and public area endeavors. We
                    offer rentals on various IT products such as Laptops,
                    Desktops, and many more.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Card style={{ border: "none" }} className="mb-5">
                <Card.Body>
                  <Card.Title className="text-uppercase">
                    <h4>BENEFIS OF CHOOSING US</h4>
                  </Card.Title>
                  <Card.Text as="div">
                    <p className="lead pt-2">
                      <FontAwesomeIcon icon={faFire} /> ZERO REPAIR COST
                    </p>
                    <p className="lead pt-2">
                      <FontAwesomeIcon icon={faFire} /> ZERO ANNUAL MAINTENANCE
                      COST
                    </p>
                    <p className="lead pt-2">
                      <FontAwesomeIcon icon={faFire} /> ZERO INVESTMENT
                    </p>
                    <p className="lead pt-2">
                      <FontAwesomeIcon icon={faFire} /> NO MINIMUM ORDER
                      QUANTITY
                    </p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row as="section" bsPrefix id="offerings" className="features-area pt-5">
        <Container>
          <Title title="OUR OFFERINGS" />

          <Row className="justify-content-center">
            <Col sm={9} md={7} lg={3}>
              <Card className="mb-5">
                <StaticImage
                  imgClassName="card-img-top fluid-img p-2"
                  src="../images/slide_3_600x500.jpeg"
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt="Hero"
                  placeholder="blurred"
                />

                <Card.Body>
                  <Card.Title className="text-uppercase text-center">
                    <Link to="/desktops" className="features-btn">
                      Desktop on rent
                    </Link>
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={9} md={7} lg={3}>
              <Card className="mb-5">
                <StaticImage
                  imgClassName="card-img-top fluid-img p-2"
                  src="../images/laptop_600x500.png"
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt="Hero"
                  placeholder="blurred"
                />

                <Card.Body>
                  <Card.Title className="text-uppercase text-center">
                    <Link to="/laptops" className="features-btn">
                      Laptop on rent
                    </Link>
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={9} md={7} lg={3}>
              <Card className="mb-5">
                <StaticImage
                  imgClassName="card-img-top fluid-img p-2"
                  src="../images/printers_2.png"
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt="Hero"
                  placeholder="blurred"
                />
                <Card.Body>
                  <Card.Title className="text-uppercase text-center">
                    <Link to="/others#printers" className="features-btn">
                      Printer on rent
                    </Link>
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={9} md={7} lg={3}>
              <Card className="mb-5">
                <StaticImage
                  imgClassName="card-img-top fluid-img p-2"
                  src="../images/cctvs_600x500.png"
                  quality={95}
                  formats={["AUTO", "WEBP"]}
                  alt="Hero"
                  placeholder="blurred"
                />
                <Card.Body>
                  <Card.Title className="text-uppercase text-center">
                    <Link to="/others#cctvs" className="features-btn">
                      CCTV on rent
                    </Link>
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Row>

      <Row
        as="section"
        bsPrefix
        id="speciality"
        className="single-features pt-5"
      >
        <Container>
          <Title title="OUR SPECIALITY" />

          <Row className="justify-content-center">
            <Col sm={9} md={7} lg={4}>
              <Card className="mb-5 pt-3 pb-2">
                <StaticImage
                  className="m-auto"
                  imgClassName="card-img-top fluid-img"
                  src="../images/courier.png"
                  formats={["AUTO", "WEBP"]}
                  alt="Hero"
                  placeholder="blurred"
                  layout="fixed"
                  width={100}
                  height={100}
                />

                <Card.Body>
                  <Card.Title className="text-center text-uppercase">
                    SAME DAY SERVICE
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={9} md={7} lg={4}>
              <Card className="mb-5 pt-3 pb-2">
                <StaticImage
                  className="m-auto"
                  imgClassName="card-img-top fluid-img"
                  src="../images/reduce-cost.png"
                  formats={["AUTO", "WEBP"]}
                  alt="Hero"
                  placeholder="blurred"
                  layout="fixed"
                  width={100}
                  height={100}
                />

                <Card.Body>
                  <Card.Title className="text-center text-uppercase">
                    LOW COST
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={9} md={7} lg={4}>
              <Card className="mb-5 pt-3 pb-2">
                <StaticImage
                  className="m-auto"
                  imgClassName="card-img-top fluid-img"
                  src="../images/authentic.png"
                  formats={["AUTO", "WEBP"]}
                  alt="Hero"
                  placeholder="blurred"
                  layout="fixed"
                  width={100}
                  height={100}
                />

                <Card.Body>
                  <Card.Title className="text-center text-uppercase">
                    Authentic Products
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Row>

      <Contact />
    </Layout>
  )
}

export default IndexPage
