import React from "react"
import "./title.css"

const Title = props => (
  <div className="row justify-content-center mb-45">
    <div className="col-lg-6 col-md-10">
      <div className="section-title text-center pb-10">
        <h3 className="title">{props.title}</h3>
        <p className="text">{props.subtitle}</p>
      </div>
    </div>
  </div>
)

export default Title
