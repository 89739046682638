import * as React from "react"
import { Carousel } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import LineIcon from "react-lineicons"

import "./banner.css"

const SliderBanner = () => {
  const isBrowser = typeof window !== "undefined"

  return (
    <>
      <section id="home" className="slider_area">
        <Carousel
          nextIcon={isBrowser && <LineIcon name="chevron-right-circle" />}
          prevIcon={isBrowser && <LineIcon name="chevron-left-circle" />}
        >
          <Carousel.Item>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="slider-content">
                    <h1 className="title">RENTAL MADE EASY</h1>
                    {/* <p className="text">
                    We blend insights and strategy to create digital products
                    for forward-thinking organisations.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-image-box d-none d-lg-flex align-items-center">
              <div className="slider-image">
                <StaticImage
                  imgClassName="fluid-img"
                  src="../images/slider/1.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Hero"
                  placeholder="blurred"
                />
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="slider-content">
                    <h1 className="title">DESKTOP ON RENT</h1>
                    {/* <p className="text">
                    Wide range of PCs, Laptops, Printers, CCTVs from well-known
                    brands such as Dell, Lenovo, HP, Apple.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="slider-image-box d-none d-lg-flex align-items-center">
              <div className="slider-image">
                <StaticImage
                  imgClassName="fluid-img"
                  src="../images/slider/2.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Hero"
                  placeholder="blurred"
                />
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="slider-content">
                    <h1 className="title">LAPTOP ON RENT</h1>
                    {/* <p className="text">
                    We blend insights and strategy to create digital products
                    for forward-thinking organisations.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="slider-image-box d-none d-lg-flex align-items-center">
              <div className="slider-image">
                <StaticImage
                  imgClassName="fluid-img"
                  src="../images/slider/3.png"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Hero"
                  placeholder="blurred"
                />
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
    </>
  )
}

export default SliderBanner
