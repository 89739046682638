import * as React from "react"
import LineIcon from "react-lineicons"
import "./contact.css"
import Title from "./title"
import ContactForm from "./contactform"
const isBrowser = typeof window !== "undefined"

const Contact = () => {
  return (
    <>
      <section id="contact" className="contact-area">
        <div className="container">
          <Title
            title="Contact Us"
            subtitle="Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within a matter of hours to help you."
          />

          <div className="row">
            <div className="col-lg-6">
              <div className="contact-map mt-30">
                <iframe
                  title="gmap-canvas"
                  id="gmap_canvas"
                  width="300"
                  height="100"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  loading="lazy"
                  allowFullScreen
                  src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJI1HCqzYYDTkR6S8vV68pYgY&key=AIzaSyBcelzPr1iAysKedcMMeJAh1KSzKTCGQiw"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-form mt-30">
                <ContactForm />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-info pt-30 d-flex justify-content-center">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="single-contact-info contact-color-1 mt-30 d-flex align-items-center">
                      <div className="contact-info-icon">
                        {isBrowser && <LineIcon name="map-marker" />}
                      </div>
                      <div className="contact-info-content media-body">
                        <p className="text">
                          #13/5, Jacobpura,
                          <br /> Opp. DHBVN office, Gurugram, Haryana 122001
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="single-contact-info contact-color-2 mt-30 d-flex align-items-center">
                      <div className="contact-info-icon">
                        {isBrowser && <LineIcon name="envelope" />}
                      </div>
                      <div className="contact-info-content media-body">
                        <p className="text">info@lappynation.com</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="single-contact-info contact-color-3 mt-30 d-flex align-items-center">
                      <div className="contact-info-icon">
                        {isBrowser && <LineIcon name="phone" />}
                      </div>
                      <div className="contact-info-content media-body">
                        <p className="text">+91 83760 07110</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Contact
